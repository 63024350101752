<template>
<nav id="sidebar" class="active">
<ul class="list-unstyled components">
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('campaigns') ? '' : 'noHover'" :to="'/Campdashboard/'+channelType">
<img class="link-img" src="@/assets/images/campaign-1.png">
<div class="link-name" > {{ $t("module.campaigns") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('schedule') ? '' : 'noHover'" to="/Schedulelist">
<img class="link-img" src="@/assets/images/schedule-1.png">
<div class="link-name" > {{ $t("module.schedule") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('contacts') ? '' : 'noHover'" to="/Contacts">
<img class="link-img" src="@/assets/images/contact-1.png">
<div class="link-name" > {{ $t("module.contacts") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('baseline') ? '' : 'noHover'" to="/Baseline">
<img class="link-img" src="@/assets/images/baseline_icon.svg">
<div class="link-name" > {{ $t("module.baseline") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('reports') ? '' : 'noHover'" to="/Reportslist">
<img class="link-img" src="@/assets/images/report-1.png">
<div class="link-name" > {{ $t("module.reports") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" to="/Approvals">
<img class="link-img" src="@/assets/images/approval.svg">
<p class="link-name"> {{ $t("module.approvals") }}</p>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" to="/ExportList">
<img class="mt-1 noHover" src="@/assets/images/download.svg">
<div class="link-name" > {{ $t("module.exports") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('links') ? '' : 'noHover'" to="/LinkDashboard">
<img class="link-img" src="@/assets/images/link.png">
<div class="link-name" > {{ $t("module.links") }}</div>
</router-link>
</li>
<li>
<router-link exact-active-class="exact-active" :class="modSubModSet.has('user_profile') ? '' : 'noHover'" to="/Userprofile">
<img class="link-img" src="@/assets/images/user-solid-white.svg">
<div class="link-name" > {{ $t("module.userProfile") }}</div>
</router-link>
</li>
<li v-if="resourceScopeMap['settings.channels']" class="mt-auto">
<router-link exact-active-class="exact-active" :to="'/SettingsList/'+tab">
<img class="link-img" src="@/assets/images/setting-1.png">
<p class="link-name" > {{ $t("module.settings") }}</p>
</router-link>
</li>
<li>
<router-link to="">
<img class="link-img" src="@/assets/images/help-1.png">
<div class="link-name" > {{ $t("module.help") }}</div>
</router-link>
</li>
</ul>
</nav>
</template>
<script>
import { mapGetters } from 'vuex'
import $ from 'jquery'
let accessToken = localStorage.getItem('Accesstoken')
$(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active')
        $(this).toggleClass('active')
    })
})
export default {
    name: 'Sidebar',
    data () {
        return {
            campaign: true
        }
    },
    props: {
        channelType: {
            type: String,
            default: 'sms'
        },
        tab: {
            type: String,
            default: 'channelSettings'
        }
    },
    computed: {
        ...mapGetters('Auth/KeycloakAuth', ['modSubModSet'])
    },
    methods: {
        created () {
            if (localStorage.getItem('reloaded')) {
                localStorage.removeItem('reloaded')
            } else {
                localStorage.setItem('reloaded', '1')
                location.reload()
            }
            if (!accessToken) {
                this.$router.push('/')
            }
        },
        logout () {
            localStorage.removeItem('Accesstoken')
            this.$router.push('/')
        }
    },
    computed: {
        ...mapGetters('Auth/KeycloakAuth', ['modSubModSet', 'resourceScopeMap'])
    }
}
</script>
 