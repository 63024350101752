/* eslint-disable indent */
import Vue from "vue";
import API from "../../config/development";
import { getToast } from "../../utils";
import i18n from '@/i18n'
const t = i18n.global.t

const state = {
    rcsTableData: [],
    rcs_total_records: 0,
};

const getters = {
    getRCSTabledata: (state) => state.rcsTableData,
};

const mutations = {
    SET_TABLEDATA: (state, payload) => {
        state.rcsTableData = payload.result;
        state.rcs_total_records = payload.totalCount;
    },
};

const actions = {
    async getRCSSettingslist({ commit }, data) {
        commit("LOADING_SPINNER_SHOW_MUTATION", false, { root: true });
        try {
            const resp = await this.$axios.post(
                `${API.Backend_URL}/settings/rcs/rcs-settings-list`,
                data
            );
            if (!resp.data.result && resp.status !== 401) {
                getToast().add({
                    severity: "error",
                    summary: t('toast.errorFetchingRcsSettings'),
                    life: 3000,
                });
            } else {
                commit("SET_TABLEDATA", resp.data);
            }
        } catch (error) {
            console.log(error);
            commit("LOADING_SPINNER_SHOW_MUTATION", false, { root: true });
        }
    },

    async getRCSSettingById({ commit }, data) {
        commit("LOADING_SPINNER_SHOW_MUTATION", false, { root: true });
        try {
            const resp = await this.$axios.post(
                `${API.Backend_URL}/settings/rcs/rcs-detail`,
                data
            );
            return resp.data;
        } catch (error) {
            console.log(error);
            commit("LOADING_SPINNER_SHOW_MUTATION", false, { root: true });
        }
    },

    async deleteRCSSetting({ commit }, data) {
        commit("LOADING_SPINNER_SHOW_MUTATION", false, { root: true });
        try {
            const resp = await this.$axios.post(
                `${API.Backend_URL}/settings/rcs/rcs-delete`,
                data
            );
            return resp.data;
        } catch (error) {
            console.log(error);
            commit("LOADING_SPINNER_SHOW_MUTATION", false, { root: true });
        }
    },

    async createRCSSetting({ commit }, data) {
        commit("LOADING_SPINNER_SHOW_MUTATION", false, { root: true });
        try {
            const resp = await this.$axios.post(
                `${API.Backend_URL}/settings/rcs/createedit`,
                data
            );
            return resp.data;
        } catch (error) {
            console.log(error);
            commit("LOADING_SPINNER_SHOW_MUTATION", false, { root: true });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
