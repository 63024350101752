import Vue from 'vue'
import API from '../../constants/api'
import CONFIG from '../../config/development'
import moment from 'moment'
import Axios from 'axios'
import forEach from 'lodash/forEach'

const state = {
  retargeting: false,
  retarget_data: {
    shortlink: true,
    smsflash: false,
    dltEnabled: false,
    messageTags: [],
    templateid: '',
    endpoint_name: '',
    senderid: '',
    smscontent: 'English',
    smsmessage: '',
    insertvariables: [],
    max_character: 160,
    max_character_multi: 70,
    total_char: '0',
    total_char_multi: '0',
    sms_part: '0',
    sms_part_multi: '0',
    max_sms_part: 8,
    max_sms_part_multi: 8,
    smslanguage: '',
    advaceprocess: false,
    cutoffdate: '',
    cutofftime: '',
    timezone_offset: '',
    deliveryspeed: '',
    duration: '',
    campstarttime: '',
    campendtime: '',
    filesdata: [],
    fileheaders: [],
    previousSmsContentType: '',
    prevSmsMessage: '',
    lastEpochForDltCheck: moment().valueOf(),
    selectedEndpointData: {},
    loadingDltTemplate: false,
    dltManualOption: false,
    suggestionsChecked: true,
    smsTransliteration: true,
    messageParts: [],
    dltTemplateUsed: false,
    inputVals: {},
    noDltMatchFound: false,
    lastSelectedInputField: 0,
    dltMatchedMessage: '',
    dltMatchedId: '',
    showPreview: false,
    showPreviewError: false,
    matchedDltIndex: 0,
    nearestDltMatches: []
  },
  content_data: {
    shortlink: true,
    smsflash: false,
    dltEnabled: false,
    messageTags: [],
    templateid: '',
    endpoint_name: '',
    senderid: '',
    smscontent: 'English',
    smsmessage: '',
    insertvariables: [],
    max_character: 160,
    max_character_multi: 70,
    total_char: '0',
    total_char_multi: '0',
    sms_part: '0',
    sms_part_multi: '0',
    max_sms_part: 8,
    max_sms_part_multi: 8,
    smslanguage: '',
    advaceprocess: false,
    cutoffdate: '',
    cutofftime: '',
    timezone_offset: '',
    deliveryspeed: '',
    duration: '',
    campstarttime: '',
    campendtime: '',
    filesdata: [],
    fileheaders: [],
    previousSmsContentType: '',
    prevSmsMessage: '',
    lastEpochForDltCheck: moment().valueOf(),
    selectedEndpointData: {},
    loadingDltTemplate: false,
    dltManualOption: false,
    suggestionsChecked: true,
    smsTransliteration: true,
    messageParts: [],
    dltTemplateUsed: false,
    inputVals: {},
    noDltMatchFound: false,
    lastSelectedInputField: 0,
    dltMatchedMessage: '',
    dltMatchedId: '',
    showPreview: false,
    showPreviewError: false,
    matchedDltIndex: 0,
    nearestDltMatches: []
  },
  languagess: [
    // { name: "Afrikaans", code: "af" },
    // { name: "Albanian", code: "sq" },
    { name: 'Arabic', code: 'ar' },
    // { name: "Armenian", code: "hy" },
    { name: 'Assamese', code: 'as' },
    { name: 'Bangla', code: 'bn' },
    // { name: "Bihari", code: "bh" },
    { name: 'Chinese', code: 'zh' },
    // { name: "Dutch", code: "nl" },
    // { name: "French", code: "fr" },
    // { name: "German", code: "de" },
    { name: 'Greek', code: 'el' },
    { name: 'Gujrati', code: 'gu' },
    { name: 'Hindi', code: 'hi' },
    // { name: "Indonesian", code: "id" },
    // { name: "Italian", code: "it" },
    { name: 'Japanese', code: 'ja' },
    // { name: "Javanese", code: "jv" },
    { name: 'Kannada', code: 'kn' },
    // { name: "Kashmiri", code: "ks" },
    { name: 'Korean', code: 'ko' },
    { name: 'Malayalam', code: 'ml' },
    { name: 'Marathi', code: 'mr' },
    { name: 'Mongolian', code: 'mn' },
    // { name: "Nepali", code: "ne" },
    { name: 'Odia/Oriya', code: 'or' },
    { name: 'Persian', code: 'fa' },
    // { name: "Portuguese", code: "pt" },
    { name: 'Punjabi', code: 'pa' },
    // { name: "Romanian", code: "ro" },
    { name: 'Russian', code: 'ru' },
    // { name: "Sanskrit", code: "sa" },
    { name: 'Sindhi', code: 'sd' },
    // { name: "Spanish", code: "es" },
    { name: 'Tamil', code: 'ta' },
    { name: 'Telugu', code: 'te' },
    { name: 'Thai', code: 'th' },
    // { name: "Turkish", code: "tr" },
    { name: 'Ukrainian', code: 'uk' },
    { name: 'Urdu', code: 'ur' }
    // { name: "Vietnamese", code: "vi" },
  ],
  contactlistarray: [],
  contactlist: [],
  senderids: [],
  isNumberAndContent: false,
  linksArray: []
}

const getters = {
  userInfo: state => state.user,
  isLoggedIn: state => state.isLoggedIn,
  isUserComplete: state => state.isUserComplete,
  fallbackData: state => state.content_data,
  getsenderList: state => state.senderids
}

const mutations = {
  SET_ContactListData: (state, payload) => {
    state.contactlist = payload.settingsData
    state.contactlistarray = payload.setArray
  },
  SET_FALLBACK_SETTINGDATA: (state, payload) => {
    state.content_data.endpoint_name = payload.smsusersno// {id:,text:getCamp.setting.smsusername}
    // state.content_data.messageTags = JSON.parse(getCamp.tags)
    state.campaignType = payload.campaignType
    // state.content_data.dltEnabled = getCamp.dltEnabled
    state.content_data.senderid = payload.senderid // { item : getCamp.setting.senderid }
    state.content_data.templateid = payload.dlttemplate
    state.content_data.smsflash = (parseInt(payload.flashtype) == 1)
    state.content_data.smslanguage = payload.contentlanguage
    state.content_data.smscontent = payload.contentype || 'English'
    state.content_data.smsmessage = payload.message ? payload.message : ''
    state.content_data.shortlink = payload.shortlink == 1
  },
  SET_senderids: (state, payload) => {
    state.senderids = payload
  }
}

const actions = {
  async getsettingsuserdata ({ commit }, payload) {
    // if (localStorage.getItem('loggedVia') !== 'Gamooga') {
    //   await this.getsenderlist()
    // }
    var data = JSON.stringify({
      status: 1
    })
    var config = {
      method: 'get',
      url: `${CONFIG.Backend_URL}/get-content-settings`,
      headers: {
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('Accesstoken')
      },
      data: data
    }
    let settingsdata = new Promise((resolve, reject) => {
      return Axios(config)
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })
    })
    let settingsData = await settingsdata
    let setArray = []
    settingsData.forEach((set) => {
      let setObj = {}
      setObj.id = set.id
      setObj.name = set.name
      setArray.push(setObj)
    })
    commit('SET_ContactListData', { settingsData, setArray })
    // this.contactlist = settingsData
    // this.contactlistarray = setArray
  },
  Selectionendpointdata ({ commit, dispatch }) {
    
    state.retarget_data.endpoint_name = state.content_data.endpoint_name
    let allignstored = state.contactlist.find(
      (contactelement) =>
        contactelement.id == state.content_data.endpoint_name
    )
    state.content_data.selectedEndpointData = allignstored || {}
    state.retarget_data.selectedEndpointData = allignstored || {}
    let senderIds = []
    if (allignstored && allignstored.senderarraysid.length > 0) {
      
      for (let i = 0; i<allignstored.senderarraysid.length; i++) {
        let senderObj = new Object()
        senderObj.id = allignstored.senderarraysid[i]
        senderObj.name = allignstored.senderarraysid[i]
        senderIds.push(senderObj)
      }
      commit('SET_senderids', senderIds)
    }
    state.content_data.suggestionsChecked = true
    state.content_data.dltManualOption = false
    state.content_data.noDltMatchFound = false
    dispatch('resetDLTTemplateInfo')
  },
  senderIdUpdated ({ dispatch }) {
    state.content_data.suggestionsChecked = true
    state.content_data.dltManualOption = false
    state.content_data.noDltMatchFound = false
    state.content_data.dltTemplateUsed = false
    dispatch('resetDLTTemplateInfo')
    dispatch('matchDltTemplates')
  },
  resetDLTTemplateInfo () {
    state.content_data.templateid = ''
    state.content_data.nearestDltMatches = []
    state.content_data.prevSmsMessage = ''
    state.content_data.showPreview = false
  },
  async matchDltTemplates ({ dispatch }, value) {
    if (value == 'mounted') state.content_data.suggestionsChecked = true
    if (!state.content_data.smsmessage) state.content_data.nearestDltMatches = []
    state.content_data.dltManualOption = true
    if (state.content_data.endpoint_name &&
        state.content_data.senderid &&
        state.content_data.smsmessage &&
        state.content_data.suggestionsChecked &&
        state.content_data.smscontent != 'Multilingual') {
      if (!(state.content_data.selectedEndpointData &&
            state.content_data.selectedEndpointData.dlt_sender_ids &&
            state.content_data.selectedEndpointData.dlt_sender_ids.includes(state.content_data.senderid))) {
        return
      }
      const subAccountSetting = state.contactlist.find(
        (contactelement) =>
          contactelement.id == state.content_data.endpoint_name
      )
      if (!subAccountSetting) {
        return
      }
      const objectId = (localStorage.getItem('loggedVia') !== 'Gamooga') ? subAccountSetting.peid : subAccountSetting.username
      const settingType = subAccountSetting.settingtype
      state.content_data.loadingDltTemplate = true
      state.content_data.dltManualOption = false
      let resp = await dispatch('CampaignManagement/CampaignManagement/getNearestDltTemplate', {
        settingType,
        objectId: objectId,
        senderId: state.content_data.senderid,
        message: state.content_data.smsmessage
      }, { root: true })
      state.content_data.loadingDltTemplate = false
      state.content_data.noDltMatchFound = false
      if (resp && resp.status && resp.status === 'error') {
        // This snippet will never be called, it's just there for safey
        // in case some files are deleted while a user is typing on this page
        state.$toast.add({ severity: 'error', summary: this.$t('toast.errorFetchingDltTemplate'), detail: resp.message, life: 3000 })
        return
      }
      if (resp && resp.matches && resp.matches.length) {
        state.content_data.matchedDltIndex = 0
        state.content_data.nearestDltMatches = resp.matches
        if (state.content_data.templateid) {
          let indexInNearest = state.content_data.nearestDltMatches.findIndex(ele => ele.templateId == state.content_data.templateid)
          if (indexInNearest != -1) {
            state.content_data.matchedDltIndex = indexInNearest
          }
        }
        forEach(state.content_data.nearestDltMatches, (nearestDltMatch, index) => {
          if (index === 0 && !state.content_data.matchedDltIndex && state.content_data.suggestionsChecked) {
            state.content_data.templateid = nearestDltMatch.templateId
          }
          if (resp.msgWords) {
            nearestDltMatch.msgWords = resp.msgWords
          }
        })
      } else {
        dispatch('resetDLTTemplateInfo')
        if (!state.content_data.dltManualOption) state.content_data.noDltMatchFound = true
      }
    }
  },
  async resetForFallback () {

  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
