/* eslint-disable camelcase */
import Vue from 'vue'
import API from '../../config/development'
import { getToast } from '../../utils'
import i18n from '@/i18n'
const t = i18n.global.t

const state = {
  whatsappTableData: [],
  whatsapp_total_records: 0,
  accessAccounts: []
}

const getters = {
  getWhatsappTabledata: state => state.whatsappTableData,
}

const mutations = {
  SET_TABLEDATA: (state, payload) => {
    state.whatsappTableData = payload.result
    state.whatsapp_total_records = payload.totalCount
  },
  SET_ACCESS_ACCOUNTS: (state, payload) => {
    state.accessAccounts = payload || []
  }
}

const actions = {

  async getWhatsappSettingslist ({ commit }, data) {
    commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    try {
      const resp = await this.$axios.post(`${API.Backend_URL}/settings/whatsapp/whatsapp-settings-list`, data)
      if (!resp.data.result && resp.status !== 401) {
        getToast().add({
          severity: 'error',
          summary: t('toast.whatsappSettingsFetchError'),
          life: 3000
        })
      } else {
        commit('SET_TABLEDATA', resp.data)
      }
    } catch (error) {
      console.log(error)
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    }
  },

  async getWhatsappSettingById ({ commit }, data) {
    commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    try {
      const resp = await this.$axios.post(`${API.Backend_URL}/settings/whatsapp/whatsapp-detail`, data)
      return resp.data
    } catch (error) {
      console.log(error)
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    }
  },

  async deleteWhatsappSetting ({ commit }, data) {
    commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    try {
      const resp = await this.$axios.post(`${API.Backend_URL}/settings/whatsapp/whatsapp-delete`, data)
      return resp.data
    } catch (error) {
      console.log(error)
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    }
  },

  async createWhatsappSetting ({ commit }, data) {
    commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    try {
      const resp = await this.$axios.post(`${API.Backend_URL}/settings/whatsapp/createedit`, data)
      return resp.data
    } catch (error) {
      console.log(error)
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    }
  },

  async fetchAccessAccounts ({ commit }, data) {
    commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    try {
      const resp = await this.$axios.post(`${API.Backend_URL}/settings/whatsapp/fetch-access-accounts`, data)
      commit('SET_ACCESS_ACCOUNTS', resp.data.result)
    } catch (error) {
      commit('LOADING_SPINNER_SHOW_MUTATION', false, { root: true })
    }
  }, 

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
