<template>
  <span>
    <router-view></router-view>
  </span>
</template>

<script>
import Navbar from './Navbar.vue'
import Sidebar from './Sidebar.vue'

export default {
    name: 'AppLayout',
    components: {
        Navbar,
        Sidebar
    }
}
</script>
