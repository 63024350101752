<template>
 <nav class="navbar navbar-expand-lg navbar-light bg-light-color custom-bar" >
   
  <!-- <a class="navbar-brand" href="#"><img src="@/assets/images/Group.png" />&nbsp;&nbsp;Wisely</a> -->
  <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button> -->
  <div class="navbar-toggler" id="navbarNavDropdown">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item active">
      </li>
      <li class="nav-item">
        {{  $t("filter") }}
      </li>
      <li class="nav-item">
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         {{userlogin}} &nbsp;
        </a>
        <div class="dropdown-menu" style="margin-left:-32px" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" @click.prevent="logout" href="#">Logout</a>
        </div>
      </li>
    </ul>
  </div>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item active">
      </li>
      <li class="nav-item">
      </li>
      <li class="nav-item" style="display: flex; align-self: center;">
        <LocaleSwitcher />
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         {{userlogin}} &nbsp;
        </a>
        <div class="dropdown-menu" style="margin-left:-32px" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" @click.prevent="logout" href="#">Logout</a>
        </div>
      </li>
    </ul>
  </div>
</nav>
</template>

<script>
import jwt_decode from "jwt-decode";
import Vue from 'vue'
import store from '../../store'
import { logout } from '../../utils'
import LocaleSwitcher from '../common/LocaleSwitcher.vue'
export default {
    name:"Navbar",
    components: { LocaleSwitcher },
    data(){
      return{
      userlogin:''
      }
    },
    methods:{
    async logout(){
      await logout(this)
      // this.$router.push('/Loginpage')
    }
  },
  mounted(){
    let accessToken = localStorage.getItem('Accesstoken')
    var token = accessToken;
    var decoded = jwt_decode(token);
    this.userlogin = decoded.name
  }
}
</script>
<style>
 @import '../../css/navbar.css'; 
 @import '../../css/integration.css'; 
</style>

 