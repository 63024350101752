// import * as rules from 'vee-validate/dist/rules'
import { defineRule, configure } from 'vee-validate';
import { all, required as baseRequired, email, integer, alpha_num, numeric, min_value, confirmed, alpha_dash, max_value } from '@vee-validate/rules'
import i18n from '../i18n'
import moment from 'moment';
const t = i18n.global.t

Object.entries(all).forEach(([name, rule]) => {
  defineRule(name, rule);
});
// install rules and localization
// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule])
// })
// localize('en', en);

// Customize required rules
defineRule('required', (value) => {
  if (!baseRequired(value)) {
    return t('validation.required');
  }
  return true;
});

defineRule('min_value', (value, [val]) => {
  const numericLength = Number(val);
  if (value >= numericLength) {
    return true;
  }
  return t('validation.minValue', { value: val });
});

defineRule('max_value', (value, [max]) => {
  const numericLength = Number(max);
  if (value <= numericLength) {
    return true;
  }
  return t('validation.maxValue', { value: max });
});

defineRule('integer', (value) => {
  if (integer(value)) {
    return true;
  }
  return t('validation.integer');
});

defineRule('alpha_num', (value) => {
  if (alpha_num(value)) {
    return true;
  }
  return t('validation.alphaNum');
});

defineRule('alpha_dash', (value) => {
  if (alpha_dash(value)) {
    return true;
  }
  return t('validation.alphaDash');
});

defineRule('min', (value, [length]) => {
  const numericLength = Number(length);
  if (value.length >= numericLength) {
    return true;
  }
  return t('validation.min', { length: length });
});

defineRule('isEnglish', (value) => {
  let nonEnglishWords = '';
  for (let i = 0; i < value.length; i++) {
    if (value.charCodeAt(i) > 128) nonEnglishWords += value[i];
  }
  if (nonEnglishWords.length <= 0) return true;
  return t('validation.isEnglish', { characters: nonEnglishWords });
});

defineRule('numeric', (value) => {
  if (numeric(value)) {
    return true;
  }
  return t('validation.numeric');
});

defineRule('url', (value) => {
  const regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/gi;
  if (regex.test(value.trim())) {
    return true;
  }
  return t('validation.url');
});

defineRule('excludeUrlScheme', (value) => {
  if (value.indexOf('http') !== 0) {
    return true;
  }
  return t('validation.excludeUrlScheme');
});

defineRule('atLeastOne', (value) => {
  if (!value) {
    return t('validation.atLeastOne');
  }
  return true;
});

defineRule('futureEpoch', (value) => {
  if (!value) return true;
  if (value >= moment(new Date()).unix()) return true;
  return t('validation.futureEpoch');
});
